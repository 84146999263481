@import '../variables';

.publish-course {
	&.loading {
		display: grid;
		justify-content: center;
		align-content: center;
		height: 100vh;
	}
	
	> .loader {
		margin: 20px auto 0 auto;
	}
	
	> .content {
		padding: 10px 20px 0 20px;
		
		> h3 {
			margin: 12px 0 6px 0;
		}
		
		> .buttons {
			$margin: 20px;
			
			display: flex;
			justify-content: center;
			margin-top: $margin;
			
			> * {
				display: block;
				height: 3rem;
				padding: 0 2rem;
				font-size: 1.25rem;
				font-weight: bold;
				text-transform: uppercase;
				border-width: 2px;
				border-style: solid;
				border-radius: 0.25rem;
				
				&:not(.disabled):not(.loading):hover {
					color: white;
				}
				
				&:not(:first-child) {
					margin-left: $margin;
				}
			}
			
			> .publish {
				color: $blue-400;
				border-color: $blue-400;
				
				&.disabled {
					color: $blue-200;
					border-color: $blue-200;
				}
				
				&:not(.disabled):not(.loading):hover {
					background: $blue-400;
				}
			}
			
			> .delete {
				$color: #e53e3e;
				
				color: $color;
				border-color: $color;
				
				&.disabled {
					color: transparentize($color, 0.5);
					border-color: transparentize($color, 0.5);
				}
				
				&:not(.disabled):not(.loading):hover {
					background: $color;
				}
			}
		}
	}
}
