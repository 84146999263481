@import '../variables';

.input {
	position: relative;
	
	> svg {
		position: absolute;
		left: 12px;
		top: 12px;
		pointer-events: none;
		color: $gray-300;
	}
	
	> input {
		width: 100%;
		padding: 6px 6px 6px 2.25em;
		color: $dark-gray;
		border: 2px solid $gray-300;
		border-radius: 0.25rem;
		outline: none;
		
		&:hover {
			border-color: $gray-400;
			
			+ svg {
				color: $gray-400;
			}
		}
		
		&:focus {
			border-color: $blue-400;
			
			+ svg {
				color: $blue-400;
			}
		}
	}
}
