@import 'variables';

body,
body *,
body ::before,
body ::after {
	font-family: $font-family;
	box-sizing: border-box;
	outline: none !important;
	border: 0 solid #e2e8f0;
}

hr {
	border: none;
	height: 2px;
	background: #f5f5f5;
}

button:disabled {
	cursor: not-allowed;
}

.new > svg {
	transform: scale(2);
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
}

main {
	display: block;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

a {
	background-color: transparent;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: bolder;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

img {
	border-style: none;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

fieldset {
	padding: 0.35em 0.75em 0.625em;
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

details {
	display: block;
}

summary {
	display: list-item;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
	margin: 0;
}

button {
	background-color: transparent;
	background-image: none;
	padding: 0;
}

fieldset {
	margin: 0;
	padding: 0;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

html {
	line-height: 1.5;
}

*,
::before,
::after {
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: #e2e8f0;
}

img {
	border-style: solid;
}

textarea {
	resize: vertical;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #a0aec0;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	color: #a0aec0;
}

input::placeholder,
textarea::placeholder {
	color: #a0aec0;
}

button,
[role='button'] {
	cursor: pointer;
}

table {
	border-collapse: collapse;
}

a {
	text-decoration: inherit;
}

// button,
input,
optgroup,
select,
textarea {
	padding: 0;
	line-height: inherit;
	color: inherit;
}

pre,
code,
kbd,
samp {
	font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
	display: block;
}

img,
video {
	max-width: 100%;
	height: auto;
}
