.courses {
	&.loading {
		display: grid;
		justify-content: center;
		align-content: center;
		height: 100vh;
	}
	
	> .header {
		display: flex;
		align-items: center;
		
		> h1 {
			margin-right: 12px;
		}
	}
}
