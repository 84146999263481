.loader {
	border-radius: 50%;
	
	animation: loader-spin 0.8s linear infinite;
}

@keyframes loader-spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
