@mixin stack {
	display: grid;
	
	> * {
		grid-row: 1;
		grid-column: 1;
	}
}

@mixin raise-on-hover {
	transition: box-shadow 0.25s, transform 0.25s;
	
	&:hover,
	&:focus {
		transform: translateY(-2px) !important;
	}
}

@mixin shadow-raise-on-hover {
	@include raise-on-hover;
	
	&:hover,
	&:focus {
		@include shadow-lg;
	}
}

@mixin shadow-lg {
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
