@import '../mixins';

.navbar {
	display: flex;
	align-items: center;
	padding: 30px 30px 0 30px;
	
	> a > h1 {
		@include raise-on-hover;
	}
	
	> .items {
		display: flex;
		align-items: center;
		margin: 0 40px 0 auto;
	}
}
