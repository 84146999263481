@import '../variables';

$height: 80px;
$vertical-padding: 8px;
$horizontal-padding: 20px;

.school-navbar {
	> .top {
		display: flex;
		align-items: center;
		height: $height;
		padding: $vertical-padding $horizontal-padding;
		border-bottom: 1.5px solid $gray-400;
		
		> img {
			max-height: $height - ($vertical-padding * 2);
			margin-right: $horizontal-padding;
		}
		
		> :last-child {
			margin-left: auto;
			cursor: pointer;
		}
	}
	
	> .menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;
		background: $gray-400;
		
		> * {
			font-weight: bold;
			
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}
}
