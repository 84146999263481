$font-family: Muli, Arial, Helvetica, sans-serif;

$screen-size-sm: 640px;
$screen-size-md: 768px;
$screen-size-lg: 1024px;
$screen-size-xl: 1280px;

$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$light-gray: #f5f6fb;
$medium-gray: #efeefa;
$dark-gray: #4a4a4a;

$red-400: #fc8181;
$red-600: #e53e3e;

$green-400: #68d391;

$blue-200: #bee3f8;
$blue-400: #63b3ed;
