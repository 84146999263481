.dashboard.home {
	> h1 {
		text-align: center;
	}
	
	> .options {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	> .schools-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 'left right';
		justify-items: center;
		
		> .left {
			grid-area: left;
		}
		
		> .right {
			grid-area: right;
		}
		
		> div > .list > a {
			display: flex;
			align-items: center;
			margin-top: 10px;
			padding: 8px 16px;
			background: lightgray;
			border-radius: 8px;
			
			> img {
				width: 50px;
				height: 50px;
				margin-right: 10px;
				border: 2px solid gray;
				border-radius: 50%;
			}
		}
	}
}
