@import '../variables';

.textarea {
	width: 100%;
	padding: 0.25rem 0.5rem;
	color: $dark-gray;
	border: 2px solid $gray-300;
	border-radius: 0.25rem;
	outline: none;
	
	&:hover {
		border-color: $gray-400;
	}
	
	&:focus {
		border-color: $blue-400;
	}
}
