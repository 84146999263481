@import '../variables';

.image-picker {
	$dimension: 175px;
	$border-width: 2px;
	
	display: grid;
	position: relative;
	align-content: center;
	width: $dimension;
	height: $dimension;
	margin: 0 auto;
	border: $border-width solid $gray-300;
	
	&.dragging {
		border-width: $border-width * 2;
		border-style: dashed;
	}
	
	&:not(.has-image) {
		cursor: pointer;
	}
	
	&,
	> img {
		border-radius: 12px;
	}
	
	> .message {
		user-select: none;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		
		> p:first-child {
			font-size: 1.1rem;
			color: transparentize($dark-gray, 0.5);
		}
		
		> p:last-child {
			font-size: 0.8rem;
			color: transparentize($dark-gray, 0.7);
		}
	}
	
	> img {
		width: $dimension - ($border-width * 2);
		height: $dimension - ($border-width * 2);
		object-fit: cover;
	}
	
	> button {
		$spacing: 8px;
		$dimension: 30px;
		
		position: absolute;
		top: $spacing;
		right: $spacing;
		width: $dimension;
		height: $dimension;
		line-height: $dimension;
		color: white;
		background: transparentize($red-400, 0.25);
		border-radius: 50%;
	}
}
