.course-display {
	&.loading {
		display: grid;
		justify-content: center;
		align-content: center;
		height: 100vh;
	}
	
	> .loader {
		margin: 20px auto 0 auto;
	}
}
